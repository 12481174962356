<template>
  <section class="conOfRequestProcess">
    <v-container fluid v-if="listData && Object.keys(listData).length > 0">
      <v-row>
        <v-col cols="12" class="pb-0">
          <div class="memberBrtCe">
            <router-link
              :to="{
                name: 'allProductsReport',
              }"
            >
              Project
            </router-link>
            <span>/</span>
            <span> {{ listData.type_name }} </span>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-space-between">
        <v-col class="pt-0">
          <div class="conOfHeader">
            <div class="headerOfPage">Request No#{{ listData.id }}</div>
          </div>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn
            v-if="
              (listData.status_cost == 2 ||
                listData.status_cost == 5 ||
                listData.status_cost === null) &&
              user.parent_id == 2
            "
            class="reminderNot btnReminderOutline confBtns mx-3"
            @click="sendProcurementCostReminder()"
            :disabled="isLoading"
            :loading="isLoading"
          >
            <v-icon>mdi-bell-outline</v-icon>Procurement
          </v-btn>
          <v-btn
            v-if="
              (listData.status_cost == 4 &&
                user.parent_id == 4 &&
                user.children_id == null) ||
              (listData.status_cost == 4 && user.parent_id == 4)
            "
            class="reminderNot btnReminderOutline confBtns"
            @click="sendCostReminder()"
            :disabled="isLoading"
            :loading="isLoading"
          >
            <v-icon>mdi-bell-outline</v-icon>sales
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col lg="8" md="7" cols="12" v-if="listData.country_id == 1">
          <div class="conOfStepper">
            <v-stepper :value="stepProduct" alt-labels elevation="0">
              <v-stepper-header>
                <v-stepper-step
                  step="1"
                  v-if="listData.status != 0"
                  :complete="stepProduct > 1"
                >
                  <div class="txtStep">Request Quote</div>
                </v-stepper-step>
                <v-stepper-step
                  step="1"
                  v-if="listData.status == 0"
                  :rules="[() => false]"
                  :complete="stepProduct > 1"
                >
                  <div class="txtStepRed">Request Closed</div>
                </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step
                  step="2"
                  v-if="listData.status_cost != 3"
                  :complete="stepProduct > 2"
                >
                  <div class="txtStep">Procurement Approval</div>
                </v-stepper-step>
                <v-stepper-step
                  step="2"
                  v-if="listData.status_cost == 3"
                  :rules="[() => false]"
                  :complete="stepProduct > 2"
                >
                  <div class="txtStepRed">Procurement Reject</div>
                </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step
                  step="3"
                  v-if="listData.status_cost != 5"
                  :complete="stepProduct > 3"
                >
                  <div class="txtStep">Sales Approval</div>
                </v-stepper-step>
                <v-stepper-step
                  step="3"
                  v-if="listData.status_cost == 5"
                  :complete="stepProduct > 3"
                  :rules="[() => false]"
                >
                  <div class="txtStepRed">Sales Reject</div>
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
          </div>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col lg="8" md="7" cols="12">
          <div class="conOfStepper" v-if="listData.country_id == 2">
            <v-stepper :value="stepProductKsa" alt-labels elevation="0">
              <v-stepper-header>
                <v-stepper-step
                  step="1"
                  v-if="listData.status != 0"
                  :complete="stepProductKsa > 1"
                >
                  <div class="txtStep">Request Quote</div>
                </v-stepper-step>
                <v-stepper-step
                  step="1"
                  v-if="listData.status == 0"
                  :rules="[() => false]"
                  :complete="stepProductKsa > 1"
                >
                  <div class="txtStepRed">Request Closed</div>
                </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step
                  step="2"
                  v-if="listData.status_cost != 5"
                  :complete="stepProductKsa > 3"
                >
                  <div class="txtStep">Sales Approval</div>
                </v-stepper-step>
                <v-stepper-step
                  step="2"
                  v-if="listData.status_cost == 5"
                  :complete="stepProductKsa == 4"
                  :rules="[() => false]"
                >
                  <div class="txtStepRed">Sales Reject</div>
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
          </div>
        </v-col>
        <template v-if="listData.cost !== null">
          <v-col cols="10" class="px-0" v-if="listData.cost.reason_description">
            <v-alert color="red lighten-2" dark>
              <div class="conOfEachCon d-flex justify-space-between">
                <div class="leftsECTION">
                  <div class="">{{ listData.cost.reason_by }}</div>
                </div>
                <div class="rightSection">
                  <div>
                    {{ listData.cost.reason_description }}
                  </div>
                </div>
              </div>
            </v-alert>
          </v-col>
        </template>
        <v-col cols="10" class="px-0" v-if="listData.reason_description">
          <v-alert color="red lighten-2" dark>
            <div class="conOfEachCon d-flex justify-space-between">
              <div class="leftsECTION">
                <div class="">{{ listData.reason_by }}</div>
              </div>
              <div class="rightSection">
                <div>
                  {{ listData.reason_description }}
                </div>
              </div>
            </div>
          </v-alert>
        </v-col>
      </v-row>

      <v-row class="justify-center">
        <v-col lg="10" md="9" cols="12">
          <div class="conOfCollapseSection">
            <v-expansion-panels accordion v-model="panel">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="headerCollapseSec">Request Details</div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="conOfContentCollapse">
                    <v-row>
                      <v-col md="4" cols="12" v-if="listData.type_name">
                        <div class="conOfEachCon">
                          <div class="sectionTitle">Service Type:</div>
                          <div class="sectionDesc">
                            {{ listData.type_name }}
                          </div>
                        </div>
                      </v-col>
                      <v-col md="4" cols="12" v-if="listData.item_name">
                        <div class="conOfEachCon">
                          <div class="sectionTitle">Item Name:</div>
                          <div class="sectionDesc">
                            {{ listData.item_name }}
                          </div>
                        </div>
                      </v-col>
                      <v-col md="4" cols="12" v-if="listData.quantity">
                        <div class="conOfEachCon">
                          <div class="sectionTitle">Quantity:</div>
                          <div class="sectionDesc">{{ listData.quantity }}</div>
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row>
                      <v-col md="4" cols="12" v-if="listData.material">
                        <div class="conOfEachCon">
                          <div class="sectionTitle">Material:</div>
                          <div class="sectionDesc">{{ listData.material }}</div>
                        </div>
                      </v-col>
                      <v-col md="4" cols="12" v-if="listData.delivery_address">
                        <div class="conOfEachCon">
                          <div class="sectionTitle">Delivery Address:</div>
                          <div class="sectionDesc">
                            {{ listData.delivery_address }}
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        md="4"
                        cols="12"
                        v-if="
                          listData.attach_files &&
                          listData.attach_files.length > 0
                        "
                      >
                        <div class="conOfEachCon">
                          <div class="sectionTitle">Attach Files:</div>
                          <div class="sectionDesc">
                            <div class="conOfAttachesFiles">
                              <div
                                class="eachAttachFile"
                                v-for="(item, index) in listData.attach_files"
                                :key="item.id"
                              >
                                <a :href="item.file" target="_blank"
                                  >Attach-File-{{ index + 1 }}</a
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row>
                      <v-col md="4" cols="12" v-if="listData.description">
                        <div class="conOfEachCon">
                          <div class="sectionTitle">Description:</div>
                          <div class="sectionDesc">
                            {{ listData.description }}
                          </div>
                        </div>
                      </v-col>
                      <v-col md="4" cols="12" v-if="listData.color">
                        <div class="conOfEachCon">
                          <div class="sectionTitle">color:</div>
                          <div class="sectionDesc">{{ listData.color }}</div>
                        </div>
                      </v-col>
                      <v-col md="4" cols="12" v-if="listData.created_at">
                        <div class="conOfEachCon">
                          <div class="sectionTitle">Request Date:</div>
                          <div class="sectionDesc">
                            {{ listData.created_at | formatDate }}
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="listData.status != 0">
                <v-expansion-panel-header>
                  <div class="headerCollapseSec">Supplier Cost</div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="conOfContentCollapse">
                    <section class="hasSupplierCost" v-if="listData.cost">
                      <v-row>
                        <v-col
                          md="4"
                          cols="12"
                          v-if="listData.cost.supplier_name"
                        >
                          <div class="conOfEachCon">
                            <div class="sectionTitle">Supplier Name:</div>
                            <div class="sectionDesc">
                              {{ listData.cost.supplier_name }}
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row>
                        <v-col md="3" cols="6" v-if="listData.cost.cost">
                          <div class="conOfEachCon">
                            <div class="sectionTitle">
                              Cost <small>(Before tax)</small>:
                            </div>
                            <div class="sectionDesc">
                              {{
                                listData.cost.actual_cost
                                  ? listData.cost.actual_cost
                                  : listData.cost.cost
                              }}
                            </div>
                          </div>
                        </v-col>
                        <v-col md="3" cols="12" v-if="listData.quantity">
                          <div class="conOfEachCon">
                            <div class="sectionTitle">
                              Quantity <small>(From Request)</small>:
                            </div>
                            <div class="sectionDesc">
                              {{ listData.quantity }}
                            </div>
                          </div>
                        </v-col>
                        <v-col md="3" cols="6" v-if="listData.cost.cost">
                          <div class="conOfEachCon">
                            <div class="sectionTitle">Total Cost:</div>
                            <div class="sectionDesc">
                              {{ listData.cost.total_cost_per_type }}
                              {{ listData.cost.currency_name }}
                            </div>
                          </div>
                        </v-col>

                        <v-col md="3" cols="6" v-if="listData.cost.cost_per_id">
                          <div class="conOfEachCon">
                            <div class="sectionTitle">Cost Per:</div>
                            <div class="sectionDesc">
                              {{ listData.cost.cost_per_type }}
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row>
                        <v-col md="6" cols="6" v-if="listData.cost.expiry_date">
                          <div class="conOfEachCon">
                            <div class="sectionTitle">Expiry Date:</div>
                            <div class="sectionDesc">
                              {{ listData.cost.expiry_date | formatDate }}
                            </div>
                          </div>
                        </v-col>
                        <v-col md="6" cols="6" v-if="listData.cost.reference">
                          <div class="conOfEachCon">
                            <div class="sectionTitle">Attach File:</div>
                            <div class="sectionDesc">
                              <a
                                :href="listData.cost.reference"
                                target="_blank"
                              >
                                Attach-File
                              </a>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row>
                        <v-col md="12" cols="12" v-if="listData.cost.note">
                          <div class="conOfEachCon">
                            <div class="sectionTitle">Notes:</div>
                            <div class="sectionDesc">
                              {{ listData.cost.note }}
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="
                          user.parent_id == 4 &&
                          user.children_id == null &&
                          listData.cost &&
                          listData.cost.status == 2
                        "
                      >
                        <v-col cols="12">
                          <div class="conBtnActionsStatus text-right mt-5">
                            <v-btn
                              class="approveWithIcon mr-3"
                              :ripple="false"
                              :disabled="isLoadingStatusBtn"
                              @click="changeStatusItem(4)"
                            >
                              <v-icon>mdi-check</v-icon>
                              Approve
                            </v-btn>
                            <v-btn
                              class="rejectWithIcon"
                              :ripple="false"
                              :disabled="isLoadingStatusBtn"
                              @click="changeStatusItem(3, true)"
                            >
                              <v-icon>mdi-close</v-icon>
                              Reject
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="
                          user.parent_id == 4 &&
                          listData.cost &&
                          (listData.cost.status == 3 ||
                            listData.cost.status == 5)
                        "
                      >
                        <v-col cols="12">
                          <div class="conBtnActionsStatus text-center mt-5">
                            <v-btn
                              class="btnAddPrimary mt-7"
                              @click="editItem(listData.cost)"
                            >
                              Edit Supplier Cost
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </section>
                    <section v-else class="noSupplierQuotes">
                      <v-row>
                        <v-col cols="12">
                          <div class="conNoSupplier d-flex justify-center">
                            <div>
                              <div class="imgNoSup">
                                <img
                                  src="@/assets/img/quotation.png"
                                  alt="No Supplier Quotes"
                                />
                              </div>
                              <div class="textNoSup text-center">
                                There’s No Supplier Quotes Added Yet
                              </div>
                            </div>
                          </div>
                          <div
                            class="conBtnNoSupplier d-flex justify-center"
                            v-if="user.parent_id == 4"
                          >
                            <v-btn
                              class="btnAddPrimary mt-7"
                              @click="dialogProject = true"
                            >
                              Add Supplier Cost
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </section>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <div
            class="conBtnActionsStatus text-right mt-5"
            v-if="
              (user.parent_id == 2 || user.id == listData.created_by_id) &&
              listData.cost &&
              listData.cost.status == 4
            "
          >
            <v-btn
              class="approveWithIcon mr-3"
              :ripple="false"
              :disabled="isLoadingStatusBtn"
              @click="changeStatusItem(6)"
            >
              <v-icon>mdi-check</v-icon>
              Approve
            </v-btn>
            <v-btn
              class="rejectWithIcon"
              :ripple="false"
              :disabled="isLoadingStatusBtn"
              @click="changeStatusItem(5, true)"
            >
              <v-icon>mdi-close</v-icon>
              Reject
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col md="10">
          <div
            class="conBtnActionsStatus text-right mt-5"
            v-if="
              user.parent_id == 4 &&
              user.children_id === null &&
              listData.status_cost == null &&
              listData.country_id == 1
            "
          >
            <v-btn
              class="rejectWithIcon"
              :ripple="false"
              :disabled="isLoadingStatusBtn"
              @click="dialogNagatRejectReason = true"
            >
              <v-icon>mdi-close</v-icon>
              Reject
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-row v-else>
      <v-col cols="12" class="mt-7">
        <EmptyState />
      </v-col>
    </v-row>
    <v-dialog v-model="dialogNagatRejectReason" max-width="500" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 modalTitle"
            >Are you sure you want to Reject
          </span>
        </v-card-title>

        <v-card-text class="conFormSubmit">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="Reason"
                  rows="3"
                  class="eachInput"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="rejectReason.reason"
                  :error-messages="rejectReasonErrors"
                  @input="$v.rejectReason.reason.$touch()"
                  @blur="$v.rejectReason.reason.$touch()"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="closeDialogNagatRejectReason">
            Cancel
          </v-btn>
          <v-btn
            class="btnPrimaryOrg"
            @click="saveNagatRejectReason"
            :disabled="isLoadingRejectReason"
            :loading="isLoadingRejectReason"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="dialogProject" max-width="800" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle"> {{ formTitle }}</span>
        </v-card-title>

        <v-card-text class="conFormSubmit scrollbar">
          <v-container>
            <v-row>
              <v-col cols="12">
                <label class="eachLabel">Supplier Name</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Supplier Name"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItem.supplier_name"
                ></v-text-field>
              </v-col>
              <v-col md="6" cols="12" v-if="user.parent_id != 10">
                <label class="eachLabel">Total Cost before tax</label>
                <div class="conOfCostSection d-flex">
                  <v-text-field
                    class="eachInput totalCost"
                    placeholder="Total Cost before tax"
                    outlined
                    solo
                    hide-details="auto"
                    v-model="formItem.cost"
                    required
                    @input="$v.formItem.cost.$touch()"
                    @blur="$v.formItem.cost.$touch()"
                    @keypress="isNumber($event)"
                  ></v-text-field>

                  <v-select
                    class="currencySec"
                    outlined
                    v-model="formItem.currency_id"
                    :items="CurrencyList"
                    item-text="name"
                    item-value="id"
                    placeholder="currency"
                    hide-details="true"
                    required
                    :menu-props="{ bottom: true, offsetY: true }"
                    @input="$v.formItem.currency_id.$touch()"
                    @blur="$v.formItem.currency_id.$touch()"
                  ></v-select>
                </div>

                <template
                  v-if="
                    $v.formItem.cost.$error || $v.formItem.currency_id.$error
                  "
                >
                  <small class="form-text text-muted">
                    <span
                      v-if="
                        !$v.formItem.cost.required ||
                        !$v.formItem.currency_id.required
                      "
                      class="color-red"
                      >Cost and currency Are Required.</span
                    >
                  </small>
                </template>
              </v-col>

              <v-col md="6" cols="12" v-if="listData.type_id != 5">
                <label class="eachLabel">Cost Per:</label>

                <v-select
                  outlined
                  v-model="formItem.cost_per_id"
                  :items="CostPerList"
                  item-text="name"
                  item-value="id"
                  placeholder="Cost Per"
                  hide-details="auto"
                  :menu-props="{ bottom: true, offsetY: true }"
                  required
                  :error-messages="costPerErrors"
                  @input="$v.formItem.cost_per_id.$touch()"
                  @blur="$v.formItem.cost_per_id.$touch()"
                  append-icon="mdi-chevron-down"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <label class="eachLabel">Notes</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Notes"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItem.note"
                ></v-text-field>
              </v-col>
              <v-col md="6" cols="12">
                <div class="conDatePicker">
                  <label class="eachLabel">Expiry Date</label>

                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="eachInput"
                        placeholder="Expiry Date"
                        solo
                        v-model="formItem.expiry_date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formItem.expiry_date"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col md="6" cols="12" v-if="listData.type_id != 5">
                <label class="eachLabel">Attach File</label>
                <v-file-input
                  v-model="formItem.reference"
                  class="eachInput"
                  outlined
                  solo
                  placeholder="Attach File"
                  filled
                  prefix="No file choose"
                  prepend-icon=""
                  show-size
                  accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*"
                >
                </v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="closeProjectModal">
            Cancel
          </v-btn>
          <v-btn
            class="btnPrimaryOrg"
            @click="saveItem"
            :disabled="isLoadingSave"
            :loading="isLoadingSave"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="successSnackbar"
      color="success"
      shaped
      absolute
      top
      right
      :timeout="timeout"
    >
      {{ successMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      color="red"
      shaped
      top
      right
      :timeout="timeout"
    >
      {{ errorMessage }}
    </v-snackbar>
    <ConfirmDialog
      v-if="dialogConform"
      v-model="dialogConform"
      @confirmAction="cofirmAction()"
      :isRejectBtn="isRejectBtn"
      :isApproveBtn="isApproveBtn"
    />
  </section>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
import EmptyState from "@/modules/shared/components/emptystate";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import ConfirmDialog from "@/modules/shared/components/confirm-popup";

export default {
  mixins: [validationMixin],
  validations() {
    const validations = {
      formItem: {
        cost_per_id: { required },
        cost: { required },
        currency_id: { required },
      },
      rejectReason: {
        reason: { required },
      },
    };
    return validations;
  },
  data: () => ({
    CostPerList: [
      {
        id: 1,
        name: "Unit",
      },
      {
        id: 2,
        name: "Total",
      },
    ],
    successSnackbar: false,
    errorSnackbar: false,
    timeout: 3000,
    successMessage: "",
    errorMessage: "",
    panel: 0,
    stepProduct: 1,
    stepProductKsa: 1,
    statusId: null,
    isLoading: false,
    isLoadingSave: false,
    isLoadingStatusBtn: false,
    dialogConform: false,
    listData: null,
    CurrencyList: [],
    isRejectBtn: false,
    isApproveBtn: false,
    dialogNagatRejectReason: false,
    isLoadingRejectReason: false,
    rejectReason: {},
    menu: false,
    dialogProject: false,
    formItem: {},
    formItems: {},
    defaultItem: {},
    editedIndex: -1,
  }),
  computed: {
    ...mapGetters(["user"]),
    formTitle() {
      return this.editedIndex === -1 ? "Add New Cost" : "Edit Cost";
    },
    costPerErrors() {
      const errors = [];
      if (!this.$v.formItem.cost_per_id.$dirty) return errors;
      !this.$v.formItem.cost_per_id.required &&
        errors.push("Cost Per Is Required.");
      return errors;
    },
    rejectReasonErrors() {
      const errors = [];
      if (!this.$v.rejectReason.reason.$dirty) return errors;
      !this.$v.rejectReason.reason.required &&
        errors.push("Reject Reason Is Required.");

      return errors;
    },
    rejectReasonMsg() {
      return this.$store.getters.rejectReason;
    },
  },
  methods: {
    sendCostReminder() {
      this.formItems.request_id = this.$route.params.id.toString();
      this.formItems.sales = 1;

      this.isLoading = true;

      console.log("request_id", this.$route.params.id);
      apiServices.post("costs/reminder", this.formItems).then((res) => {
        if (res) {
          this.successMessage = res.message;
          this.successSnackbar = true;
          this.isLoading = false;
        } else {
          this.errorMessage = res.data.error || "Somthing Error";
          this.errorSnackbar = true;
          this.isLoadingSavePO = false;
        }
      });
    },
    sendProcurementCostReminder() {
      this.formItems.request_id = this.$route.params.id.toString();
      // this.formItems.sales = 1;

      this.isLoading = true;

      console.log("request_id", this.$route.params.id);
      apiServices.post("costs/reminder", this.formItems).then((res) => {
        if (res) {
          this.successMessage = res.message;
          this.successSnackbar = true;
          this.isLoading = false;
        } else {
          this.errorMessage = res.data.error || "Somthing Error";
          this.errorSnackbar = true;
          this.isLoadingSavePO = false;
        }
      });
    },

    saveNagatRejectReason() {
      this.isLoadingRejectReason = true;
      let rejectReason = {
        job_order_id: this.$route.params.id,
        request_id: this.$route.params.id,

        reason: this.rejectReason.reason,
      };

      apiServices.post("request/reject", rejectReason).then((res) => {
        if (res) {
          this.isLoadingRejectReason = false;
          this.closeDialogNagatRejectReason();
        } else {
          this.isLoadingRejectReason = false;
        }
      });
    },
    closeDialogNagatRejectReason() {
      this.dialogNagatRejectReason = false;

      this.$nextTick(() => {
        this.$v.$reset();
        this.rejectReason = Object.assign({}, this.defaultReject);
      });
    },
    changeStatusItem(statusId, isRejectBtn, isApproveBtn) {
      this.statusId = statusId;
      this.dialogConform = true;
      this.isRejectBtn = isRejectBtn;
      this.isApproveBtn = isApproveBtn;
    },
    cofirmAction() {
      this.isLoadingStatusBtn = true;
      let costBody = {
        cost_id: this.listData.cost.id,
        reason: this.rejectReasonMsg,
        status: this.statusId,
      };
      apiServices.post("costs/status", costBody).then((res) => {
        if (res) {
          this.isLoadingStatusBtn = false;
          this.dialogConform = false;
          this.getListData(this.$route.params.id);
        } else {
          this.isLoadingStatusBtn = false;
          this.dialogConform = false;
        }
      });
    },
    closeConfirmDialog() {
      this.statusId = null;
      this.dialogConform = false;
    },
    closeProjectModal() {
      this.dialogProject = false;
      this.editedIndex = -1;
      this.$nextTick(() => {
        this.$v.$reset();
        this.formItem = Object.assign({}, this.defaultItem);
      });
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    editItem(item) {
      this.editedIndex = item.id;
      let editFormItem = {
        supplier_name: item.supplier_name,
        cost: item.cost_name,
        currency_id: item.currency_id,
        cost_per_id: item.cost_per_id,
        note: item.note,
        expiry_date: item.expiry_date,
        // reference: item.reference,
      };

      this.formItem = editFormItem;

      this.dialogProject = true;
    },
    itemEdited() {
      this.$v.formItem.$touch();
      if (this.$v.formItem.$error) return;
      this.isLoadingSave = true;
      this.formItem.request_id = this.listData.id;
      var form_data = new FormData();
      for (var key in this.formItem) {
        form_data.append(key, this.formItem[key]);
      }

      apiServices
        .post(`costs/update/${this.editedIndex}`, form_data)
        .then((res) => {
          if (res) {
            this.getListData(this.$route.params.id);
            this.isLoadingSave = false;
            this.closeProjectModal();
          } else {
            this.isLoadingSave = false;
          }
        });
    },
    itemAddNew() {
      if (this.listData.type_id == 5) {
        this.formItem.cost_per_id = "2";
      }

      this.$v.$touch();
      if (this.$v.formItem.$error) return;
      this.isLoadingSave = true;
      this.formItem.request_id = this.listData.id;

      var form_data = new FormData();
      for (var key in this.formItem) {
        form_data.append(key, this.formItem[key]);
      }
      apiServices.post("costs", form_data).then((res) => {
        if (res) {
          this.getListData(this.$route.params.id);
          this.isLoadingSave = false;
          this.closeProjectModal();
        } else {
          this.isLoadingSave = false;
        }
      });
    },
    saveItem() {
      if (this.editedIndex > -1) {
        this.itemEdited();
      } else {
        this.itemAddNew();
      }
    },
    getCurrencyListData() {
      this.CurrencyList = [];
      apiServices.get(`currencies?per_page=100`).then((res) => {
        if (res) {
          this.CurrencyList = res.data;
        }
      });
    },
    getStepWizard(data, cost) {
      if (data.country_id == 2) {
        if (!cost) this.stepProductKsa = 1;
        else if (cost.status == 4) this.stepProductKsa = 2;
        else if (cost.status == 5) this.stepProductKsa = 3;
        else if (cost.status == 6) this.stepProductKsa = 4;
      } else {
        if (!cost) this.stepProduct = 1;
        else if (cost.status == 2 || cost.status == 3 || cost.status == 5)
          this.stepProduct = 2;
        else if (cost.status == 4) this.stepProduct = 3;
        else if (cost.status == 6) this.stepProduct = 4;
      }
    },
    getListData(id) {
      this.listData = "";
      this.isLoading = true;
      apiServices.get(`requests/${id}`).then((res) => {
        if (res) {
          this.listData = res.data;
          console.log("listData", this.listData);
          if (res.data.cost) {
            this.getStepWizard(res.data, res.data.cost);
          }
          this.isLoading = false;
        } else this.isLoading = false;
      });
    },
  },
  created() {
    this.getCurrencyListData();
  },
  components: { EmptyState, ConfirmDialog },
  watch: {
    "$route.params.query": {
      handler: function () {
        this.getListData(this.$route.params.id);
      },
      deep: true,
      immediate: true,
    },
    dialogConform(val) {
      val || this.closeConfirmDialog();
    },
    dialogProject(val) {
      val || this.closeProjectModal();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_request-process.scss";
</style>
